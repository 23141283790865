<template>
  <div class="detail">
    <div class="content_area">
      <div v-html="info.content">

      </div>
    </div>
  </div>
</template>

<script>
import {_decode} from "@/basePlugins/base64";

export default {
  name: "detail",
  data(){
    return {
      info_id:'',
      info:{},
    }
  },
  created(){
    let queryParams = _decode(this.$route.query.parameter)
    this.info_id=queryParams.info_id
    this.getDetail()
  },
  mounted() {
  },
  methods:{
    async getDetail(){
      try {
      let params={}
      params.id=this.info_id

        let res= await this.$store.dispatch('API_index/getStaticContent',params)
        if (res.success){
          this.info=res.data
        }
      }catch (e) {
      }
    }
  }
}
</script>

<style scoped lang="less">
.detail{
  padding: 50px 0;
}
.content_area{
}
</style>
